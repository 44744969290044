<template>
    <div :class="`p-2 d-flex flex-column flex-fill ${$isMobile() ? '' : 'container-lg'}`">
        
        <div class="mb-2">
            <div class="">
                <h2 class="h2 text-white font-weight-bolder mb-0 pr-1" style="padding-bottom:.5em;border-bottom: 1px solid #FFAD32;width: fit-content;">Confirmation</h2>
            </div> 

            <div class="d-flex mt-2 mb-1 align-items-center justify-content-between" style="gap:10px">
                <div class="h3 font-weight-bolder text-white mb-0" v-if="input_data.item">
                    Donation {{ input_data.item.shd_title }}
                </div>
                <div class="h3 font-weight-bolder text-white mb-0 d-flex align-items-center">
                    <i class="bx bx-dollar" style="font-size: 20px;" ></i> {{ input_data.nominal }}
                </div>
            </div>

            <div>
                <!-- <div class="h5 text-white">
                    Wednesday 18th May 2022, 09:00 am
                </div> -->
                <p class="text-white" v-if="input_data.item">
                    {{ input_data.item.shd_details }}
                </p>
            </div>

            <!-- PAYMENT -->
            <div class="d-flex mt-3 mb-1 align-items-center justify-content-between" style="gap:10px">
                <div class="h3 font-weight-bolder text-white mb-0">
                    Payment
                </div> 
            </div>

            <p class="text-white">For payment you can call the shelter directly.</p>
            <!-- END PAYMENT -->

            <!-- SUMMARY -->
            <div class="d-flex mt-3 align-items-center justify-content-between" style="gap:10px">
                <div class="h3 font-weight-bolder text-white mb-0">
                    Summary
                </div> 
            </div> 
            <div class="d-flex mt-1 align-items-center justify-content-between" style="gap:10px">
                <div class="h4 font-weight-normal mb-0" style="color: #E4E4E4;">
                    Donation
                </div>
                <div class="h4 font-weight-normal mb-0 d-flex align-items-center" style="color: #E4E4E4;">
                    <i class="bx bx-dollar" style="font-size: 20px;" ></i> {{ input_data.nominal }}
                </div>
            </div>
            <!-- END SUMMARY -->
        </div>

        <div class="mt-auto">
            <hr style="border-color: #FFAD32;" />
            <div class="text-white">Total Payment</div>

            <div class="" style="margin-top: .5em;">
                <div class="d-flex align-items-center justify-content-between" style="gap:10px">
                    <div class="h3 font-weight-bolder text-white mb-0 d-flex align-items-center">
                        <i class="bx bx-dollar" style="font-size: 20px;" ></i> {{ input_data.nominal }}
                    </div>
                    <div>
                        <div class="text-white d-flex align-items-center" @click="confirm_donate()" style="padding:.5em 3em;gap:10px;background-color: #1B628C;border: 2px solid #FFAD32;border-radius: 10px;">
                            <div class="h4 text-white mb-0 ml-auto">Pay</div>
                        </div>
                    </div>
                </div>
            </div>

        </div>

    </div>
</template>

<script>

import store from '@/store'

export default {
    mounted(){
        this.input_data = this.$route.query
    },
    methods: {
        confirm_donate(){
            store.dispatch('shelter/ApplyDonation', {
                user_id: this.input_data.user_id,
                shd_id: this.input_data.shd_id,
                shelter_id: this.input_data.shelter_id,
                nominal: this.input_data.nominal,
            }).then(() => {
                // this.$router.push(`/shelter/donate/${this.input_data.shelter_id}`)
                this.$router.push({ path: '/shelter/donate/invoice/'+this.input_data.shelter_id, query: {
                    user_id: this.input_data.user_id,
                    shd_id: this.input_data.shd_id,
                    shelter_id: this.input_data.shelter_id,
                    nominal: this.input_data.nominal,
                    shd_title: this.input_data.item.shd_title,
                    shd_details: this.input_data.item.shd_details
                }})
            })
        }
    },
    data(){
        return {
            input_data: {}
        }
    }
}

</script>

<style>
input[type='radio']:after {
    width: 15px;
    height: 15px;
    border-radius: 15px;
    top: -2px;
    left: -1px;
    position: relative;
    background-color: #d1d3d1;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 2px solid white;
}

input[type='radio']:checked:after {
    width: 15px;
    height: 15px;
    border-radius: 15px;
    top: -2px;
    left: -1px;
    position: relative;
    background-color: #ffa500;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 2px solid white;
}
</style>